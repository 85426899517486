define("driver-portal/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DpHoISTy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-4 col-sm-offset-4\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"img\"],[15,\"src\",[30,[36,0],[\"/img/logo-driver.svg\"],null]],[14,0,\"w-lg cursor-pointer\"],[15,\"alt\",[30,[36,1],[\"taxify_driver_portal\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hr-divider m-y\"],[12],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"hr-divider-content hr-divider-heading\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"enter_new_password\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"branded-asset-map\",\"tb\",\"link-to\",\"-outlet\",\"component\"]}",
    "moduleName": "driver-portal/templates/reset-password.hbs"
  });

  _exports.default = _default;
});