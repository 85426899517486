define("driver-portal/services/window-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    reload: function reload() {
      window.location.reload();
    }
  });

  _exports.default = _default;
});