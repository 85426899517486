define("driver-portal/utils/response-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ResponseCodes;

  (function (ResponseCodes) {
    ResponseCodes[ResponseCodes["OK"] = 0] = "OK";
    ResponseCodes[ResponseCodes["DATABASE_ERROR"] = 1000] = "DATABASE_ERROR";
    ResponseCodes[ResponseCodes["REFRESH_TOKEN_INVALID"] = 210] = "REFRESH_TOKEN_INVALID";
    ResponseCodes[ResponseCodes["NOT_AUTHORIZED"] = 503] = "NOT_AUTHORIZED";
    ResponseCodes[ResponseCodes["INVALID_REQUEST"] = 702] = "INVALID_REQUEST";
    ResponseCodes[ResponseCodes["DRIVER_REGISTRATION_FORM_VALIDATION_FAILED"] = 5012] = "DRIVER_REGISTRATION_FORM_VALIDATION_FAILED";
    ResponseCodes[ResponseCodes["DRIVER_REGISTRATION_FORM_VALIDATION_FAILED_NEW"] = 61907] = "DRIVER_REGISTRATION_FORM_VALIDATION_FAILED_NEW";
    ResponseCodes[ResponseCodes["DRIVER_REGISTRATION_LOG_NOT_IN_PROGRESS"] = 5011] = "DRIVER_REGISTRATION_LOG_NOT_IN_PROGRESS";
    ResponseCodes[ResponseCodes["DRIVER_PORTAL_CAR_APPLICATION_VALIDATION_FAILED"] = 5017] = "DRIVER_PORTAL_CAR_APPLICATION_VALIDATION_FAILED";
  })(ResponseCodes || (ResponseCodes = {}));

  var _default = ResponseCodes;
  _exports.default = _default;
});