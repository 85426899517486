define("driver-portal/utils/forms-of-work", ["exports", "driver-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFormOfWorks = getFormOfWorks;
  _exports.isContractNumberRequiredForFormOfWork = isContractNumberRequiredForFormOfWork;
  var config = _environment.default.DP.profile.forms_of_work;

  function getFormOfWorks(country) {
    var _config$country;

    return ((_config$country = config[country]) === null || _config$country === void 0 ? void 0 : _config$country.values) || [];
  }

  function isContractNumberRequiredForFormOfWork(country, formOfWork) {
    var _config$country2, _config$country2$cont;

    return ((_config$country2 = config[country]) === null || _config$country2 === void 0 ? void 0 : (_config$country2$cont = _config$country2.contract_number_required) === null || _config$country2$cont === void 0 ? void 0 : _config$country2$cont.includes(formOfWork)) || false;
  }
});