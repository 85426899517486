define("driver-portal/helpers/no-rtl-i18n", ["exports", "@bolteu/ember-commons/helpers/no-rtl-i18n"], function (_exports, _noRtlI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _noRtlI18n.default;
    }
  });
  Object.defineProperty(_exports, "noRtlI18n", {
    enumerable: true,
    get: function get() {
      return _noRtlI18n.noRtlI18n;
    }
  });
});