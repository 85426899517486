define("driver-portal/utils/cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    expiresInString: function expiresInString() {
      var expiresInDays = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 90;
      var d = new Date();
      d.setTime(d.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
      return 'expires=' + d.toUTCString();
    },
    getDomain: function getDomain() {
      var host = window.location.host;
      var hostWithoutPort = host.substring(0, host.lastIndexOf(':') !== -1 ? host.lastIndexOf(':') : undefined);
      return hostWithoutPort.replace('partners.', '');
    },
    set: function set(name, value, expiresInDays) {
      document.cookie = name + '=' + value + ';  domain=' + this.getDomain() + '; ' + this.expiresInString(expiresInDays) + '; path=/;';
    },
    get: function get(name) {
      var cookieName = name + '=';
      var ca = document.cookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(cookieName) === 0) {
          return c.substring(cookieName.length, c.length);
        }
      }

      return null;
    },
    delete: function _delete(name) {
      document.cookie = name + '=; domain=' + this.getDomain() + '; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
    }
  };
  _exports.default = _default;
});