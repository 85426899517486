define("driver-portal/templates/signup/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wyRR8Y7v",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-10 col-sm-offset-1\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3],[35,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"col-sm-12 m-b-lg\"],[12],[2,\"\\n            \"],[1,[30,[36,1],null,[[\"step\"],[[35,0,[\"currentStepIndex\"]]]]]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n          \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row m-t-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n      \"],[1,[34,8]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"signup-progress-bar\",\"isDocumentsResubmission\",\"isFinished\",\"or\",\"unless\",\"-outlet\",\"component\",\"language-selector\"]}",
    "moduleName": "driver-portal/templates/signup/details.hbs"
  });

  _exports.default = _default;
});