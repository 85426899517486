define("driver-portal/helpers/is-profile-section-visible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isProfileSectionVisible = isProfileSectionVisible;
  _exports.default = void 0;

  var _PROFILE_SECTIONS;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SectionName;

  (function (SectionName) {
    SectionName["BILLING_INFORMATION"] = "billing_information";
    SectionName["INVOICE_ISSUER_INFORMATION"] = "invoice_issuer_information";
    SectionName["ACCOUNT_INFORMATION"] = "account_information";
    SectionName["TAX_COMPLIANCE"] = "tax_compliance";
  })(SectionName || (SectionName = {}));

  var PROFILE_SECTIONS = (_PROFILE_SECTIONS = {}, _defineProperty(_PROFILE_SECTIONS, SectionName.BILLING_INFORMATION, ['company_name', 'company_email', 'address', 'reg_code', 'vat_code']), _defineProperty(_PROFILE_SECTIONS, SectionName.INVOICE_ISSUER_INFORMATION, ['legal_type', 'invoice_issuer_name', 'invoice_issuer_address', 'invoice_issuer_reg_code', 'invoice_issuer_vat_code']), _defineProperty(_PROFILE_SECTIONS, SectionName.ACCOUNT_INFORMATION, ['account_recipient', 'account_iban', 'account_swift', 'account_branch_code', 'account_type']), _defineProperty(_PROFILE_SECTIONS, SectionName.TAX_COMPLIANCE, ['birthday', 'tax_identification_numbers', 'country_of_birth']), _PROFILE_SECTIONS);

  function isProfileSectionVisible(params) {
    var _params = _slicedToArray(params, 2),
        model = _params[0],
        sectionName = _params[1];

    var visible = false;
    var hiddenFields = model.getWithDefault('hidden_fields', []);
    PROFILE_SECTIONS[sectionName].forEach(function (fieldName) {
      if (!hiddenFields.includes(fieldName)) {
        visible = true;
      }
    });
    return visible;
  }

  var _default = Ember.Helper.helper(isProfileSectionVisible);

  _exports.default = _default;
});