define("driver-portal/initializers/support-for-legacy-query-urls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var location = window.location;
    var hash = location.hash;

    if (hash.indexOf('#?') === 0) {
      var queryString = hash.substr(1, hash.length);
      location.hash = "#/".concat(queryString);
    }
  }

  var _default = {
    name: 'support-for-legacy-query-urls',
    initialize: initialize
  };
  _exports.default = _default;
});