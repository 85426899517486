define("driver-portal/models/invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    serial: attr('string'),
    public_id: attr('string'),
    invoice_date: attr('number'),
    due_date: attr('number'),
    currency: attr('string'),
    sum_with_vat: attr('number')
  });

  _exports.default = _default;
});