define("driver-portal/utils/find-locale-by-region", ["exports", "driver-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = findLocaleByRegion;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function findLocaleByRegion(region) {
    var preferredLanguages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var languages = _environment.default.translations.languages;
    var regionLocales = [];

    for (var i = 0; i < languages.length; i++) {
      var lang = languages[i];

      if (lang.regions.includes(region)) {
        regionLocales.push(lang.key);
      }
    } // Try to match given preferred locales/languages
    // with any of the supported locale in given region


    var _iterator = _createForOfIteratorHelper(preferredLanguages),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var preferred = _step.value;

        for (var _i = 0; _i < regionLocales.length; _i++) {
          var locale = regionLocales[_i] || '';
          var localeLang = locale.substring(0, 2);

          if (locale === preferred || localeLang === preferred) {
            return locale;
          }
        }
      } // Return first regional locale if we have no preferences

    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    if (preferredLanguages.length === 0 && regionLocales.length > 0) {
      return regionLocales[0];
    }

    return null;
  }
});