define("driver-portal/initializers/rewrite-hash-url-to-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var location = window.location;
    var hash = location.hash;

    if (hash.length > 0) {
      window.history.replaceState({}, '', hash.substr(1, hash.length));
    }
  }

  var _default = {
    name: 'rewrite-hash-url-to-history',
    initialize: initialize,
    after: 'support-for-legacy-query-urls'
  };
  _exports.default = _default;
});