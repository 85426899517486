define("driver-portal/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ob/LDy+v",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-4 col-sm-offset-4\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"\\n\"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"img\"],[15,\"src\",[30,[36,1],[\"/img/logo-driver.svg\"],null]],[14,0,\"w-lg cursor-pointer\"],[15,\"alt\",[30,[36,0],[\"taxify_driver_portal\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hr-divider m-y\"],[12],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"hr-divider-content hr-divider-heading\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"login\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n      \"],[10,\"hr\"],[12],[13],[2,\"\\n      \"],[1,[34,5]],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,0],[\"not_a_driver\"],null]],[13],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"signup\"],null]]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"class\"],[\"btn-xs\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tb\",\"branded-asset-map\",\"new-landing-page-link\",\"-outlet\",\"component\",\"app-store-links\",\"language-selector\"]}",
    "moduleName": "driver-portal/templates/login.hbs"
  });

  _exports.default = _default;
});