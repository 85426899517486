define("driver-portal/templates/components/vehicle-application/alert-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pIBA1SgA",
    "block": "{\"symbols\":[\"@comment\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[\"my-lg-3 my-sm-2 p-lg-3 p-sm-3 alert alert-custom alert-warning\"],null]],[14,\"role\",\"alert\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon icon-info-with-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex flex-column flex-wrap\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"alert-title text-bold\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],[\"auth.add-vehicle.application-in-review.title\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"span\"],[12],[1,[30,[36,1],[[30,[36,0],[\"auth.add-vehicle.application-in-review.description\"],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"tb\",\"if\"]}",
    "moduleName": "driver-portal/templates/components/vehicle-application/alert-warning.hbs"
  });

  _exports.default = _default;
});