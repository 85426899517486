define("driver-portal/templates/signup/details/step2-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBQDd8aO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"text-center\"],[12],[10,\"strong\"],[12],[1,[30,[36,0],[\"error\"],null]],[13],[13],[2,\"\\n\"],[6,[37,2],[[35,1,[\"message\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"text-center lead\"],[12],[1,[30,[36,0],[[35,1,[\"message\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"text-center lead\"],[12],[1,[30,[36,0],[\"loading_city_data_failed\"],null]],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"text-center lead\"],[12],[1,[30,[36,0],[\"failed_to_contact_servers\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"ul\"],[12],[2,\"\\n  \"],[10,\"li\"],[12],[2,\"\\n    \"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"errorpage_taxify_driver_registration\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[12],[6,[37,4],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"errorpage_driver_portal_login\"],null]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tb\",\"model\",\"if\",\"new-landing-page-link\",\"link-to\"]}",
    "moduleName": "driver-portal/templates/signup/details/step2-error.hbs"
  });

  _exports.default = _default;
});