define("driver-portal/templates/components/document-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yTfp6Fr9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"document-container m-t\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,4],[[35,2,[\"document\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,2,[\"document\",\"is_expired\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"alert alert-danger\"],[14,\"role\",\"alert\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"document.is_expired\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,2,[\"document\",\"expires_in_days\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"alert alert-danger\"],[14,\"role\",\"alert\"],[12],[2,\"\\n          \"],[1,[30,[36,4],[[30,[36,3],[[35,2,[\"document\",\"expires_in_days\"]],2],null],[30,[36,1],[\"document.expires_in_days\"],[[\"days\"],[[35,2,[\"document\",\"expires_in_days\"]]]]],[30,[36,1],[\"document.expires_in_one_day\"],null]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"col-xs-12 mb-5\"],[12],[2,\"\\n      \"],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger pull-right document-required\"],[12],[1,[30,[36,0],[\"required\"],null]],[2,\" *\"],[13]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"document-title\"],[12],[1,[35,2,[\"field_title\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"document-description\"],[12],[1,[30,[36,6],[[35,2,[\"field_description\"]]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"tb\",\"t\",\"document\",\"gte\",\"if\",\"isRequiredLabelShown\",\"basic-markdown\"]}",
    "moduleName": "driver-portal/templates/components/document-details.hbs"
  });

  _exports.default = _default;
});