define("driver-portal/templates/components/new-landing-page-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G/l1gMdh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"a\"],[15,6,[31,[[32,0,[\"newLandingPageUrl\"]]]]],[12],[2,\"\\n\"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "driver-portal/templates/components/new-landing-page-link.hbs"
  });

  _exports.default = _default;
});