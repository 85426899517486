define("driver-portal/templates/pending-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jUNSNNWd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pending-redirect-centered\"],[12],[1,[30,[36,0],[\"internal_redirect.redirect_message\"],null]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-bold\"],[12],[2,\" \"],[1,[34,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[34,2]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"tb\",\"url\",\"splash-screen\"]}",
    "moduleName": "driver-portal/templates/pending-redirect.hbs"
  });

  _exports.default = _default;
});