define("driver-portal/templates/components/splash-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0qhncgVJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"splash-screen\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sk-circle\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle1 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle2 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle3 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle4 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle5 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle6 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle7 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle8 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle9 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle10 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle11 sk-child\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"sk-circle12 sk-child\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "driver-portal/templates/components/splash-screen.hbs"
  });

  _exports.default = _default;
});