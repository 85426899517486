define("driver-portal/templates/practice-ride", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wGfN5dD7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"style\"],[12],[2,\"\\n  .practice-ride-container {\\n    height: 0;\\n    overflow: hidden;\\n    display: block;\\n    padding: 0;\\n  }\\n\\n  .practice-ride-container > iframe {\\n    position: absolute;\\n    top: 0;\\n    left: 0;\\n  }\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"practice-ride-container\"],[12],[2,\"\\n  \"],[10,\"iframe\"],[14,\"title\",\"practice-ride\"],[14,\"width\",\"100%\"],[14,\"height\",\"100%\"],[15,\"src\",[34,0]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"prototypeUrl\"]}",
    "moduleName": "driver-portal/templates/practice-ride.hbs"
  });

  _exports.default = _default;
});