define("driver-portal/templates/signup/step1/form-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rQPvLiI3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"lead\"],[12],[10,\"strong\"],[12],[1,[30,[36,0],[\"regstep1.error-page.title\"],null]],[13],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[1,[30,[36,0],[\"regstep1.error-page.p1\"],null]],[13],[2,\"\\n\"],[10,\"p\"],[12],[10,\"strong\"],[12],[1,[30,[36,0],[\"regstep1.error-page.p2\"],null]],[13],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-success\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"retry\"],null],[12],[1,[30,[36,0],[\"regstep1.error-page.retry-btn\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"tb\",\"action\"]}",
    "moduleName": "driver-portal/templates/signup/step1/form-error.hbs"
  });

  _exports.default = _default;
});