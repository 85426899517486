define("driver-portal/templates/auth/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AtgHqlZc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"dashhead\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"dashhead-title\"],[12],[1,[30,[36,0],[\"error\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[35,1,[\"message\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"tb\",\"model\"]}",
    "moduleName": "driver-portal/templates/auth/error.hbs"
  });

  _exports.default = _default;
});