define("driver-portal/utils/build-api-url", ["exports", "driver-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildApiUrlFromPath;

  function buildApiUrlFromPath(path) {
    var host = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _environment.default.DP.API.URL;
    var chunks = path.split('/').map(function (value) {
      return value.trim();
    }).filter(function (value) {
      return value.length > 0;
    }).join('/');
    return "".concat(host, "/").concat(chunks, "/?version=").concat(_environment.default.DP.prefixedVersion, "&brand=").concat(_environment.default.DP.brand);
  }
});