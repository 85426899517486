define("driver-portal/components/select-picker/native-optgroup", ["exports", "@bolteu/ember-commons/components/select-picker/native-optgroup"], function (_exports, _nativeOptgroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _nativeOptgroup.default;
    }
  });
});