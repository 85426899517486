define("driver-portal/templates/components/vehicle-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JNFvowy3",
    "block": "{\"symbols\":[\"@status\"],\"statements\":[[10,\"label\"],[15,0,[31,[\"label \",[34,0]]]],[12],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],[\"auth.vehicle.status.\",[32,1]],null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"statusLabelClass\",\"concat\",\"tb\"]}",
    "moduleName": "driver-portal/templates/components/vehicle-status.hbs"
  });

  _exports.default = _default;
});