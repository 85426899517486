define("driver-portal/templates/components/confirmation-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JmmBjgxm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"modal-backdrop\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"confirmation-dialog modal-dialog\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal modal-open\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n          \"],[10,\"h2\"],[14,0,\"modal-title\"],[12],[1,[30,[36,2],[[35,3]],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n          \"],[10,\"p\"],[12],[1,[30,[36,2],[[35,4]],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"button\"],[14,0,\"btn confirm\"],[14,4,\"button\"],[12],[2,\"\\n                \"],[10,\"i\"],[14,0,\"spinner\"],[12],[13],[2,\"\\n              \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"button\"],[15,\"onClick\",[30,[36,0],[[32,0],\"confirmAction\"],null]],[14,0,\"btn confirm\"],[14,4,\"button\"],[12],[2,\"\\n              \"],[1,[30,[36,2],[[35,1]],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[10,\"button\"],[15,\"onClick\",[30,[36,0],[[32,0],\"cancelAction\"],null]],[14,0,\"btn cancel\"],[14,4,\"button\"],[12],[2,\"\\n            \"],[1,[30,[36,2],[[35,7]],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"confirm\",\"tb\",\"title\",\"text\",\"confirmInProgress\",\"if\",\"cancel\"]}",
    "moduleName": "driver-portal/templates/components/confirmation-dialog.hbs"
  });

  _exports.default = _default;
});