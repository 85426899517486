define("driver-portal/services/geolocation", ["exports", "@bolteu/ember-commons/services/geolocation", "driver-portal/config/environment"], function (_exports, _geolocation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _geolocation.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_geoServiceDomain', _environment.default.taxify.service.geo.url);
    }
  });

  _exports.default = _default;
});