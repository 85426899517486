define("driver-portal/utils/is-local-storage-available", ["exports", "@bolteu/ember-commons/utils/is-local-storage-available"], function (_exports, _isLocalStorageAvailable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isLocalStorageAvailable.default;
    }
  });
});