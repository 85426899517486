define("driver-portal/models/order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    state: attr('string'),
    address: attr('string'),
    created: attr('number'),
    ride_price: attr('number'),
    payment_method: attr('string'),
    price_review_finished_timestamp: attr('number'),
    ride_distance: attr('number'),
    ride_distance_str: attr('string'),
    price_data: attr(),
    inPriceReview: Ember.computed('price_review_finished_timestamp', function () {
      return Ember.isEmpty(this.price_review_finished_timestamp);
    })
  });

  _exports.default = _default;
});