define("driver-portal/services/translations", ["exports", "@bolteu/ember-commons/services/translations", "driver-portal/config/environment"], function (_exports, _translations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _translations.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.appVersion = _environment.default.APP.version; // Safe guarding because non CDN apps do not define it.

      if (_environment.default.taxify && _environment.default.taxify.service && _environment.default.taxify.service.translations) {
        this.urlPrefix = _environment.default.taxify.service.translations.urlPrefix;
      }
    }
  });

  _exports.default = _default;
});