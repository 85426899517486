define("driver-portal/data/fleet-info-static-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    en: {
      cities: [{
        city: 'Zagreb',
        fleetContacts: [{
          name: 'Miroslav',
          number: '+385923000983'
        }, {
          name: 'Toni',
          number: '+385976252024'
        }, {
          name: 'Stjepan',
          number: '+385957315650'
        }, {
          name: 'Dalibor',
          number: '+385953575659'
        }, {
          name: 'Željko',
          number: '+385955795500'
        }, {
          name: 'Ivica',
          number: '+38598751875'
        }, {
          name: 'Željko',
          number: '+38598666611'
        }]
      }, {
        city: 'Split',
        fleetContacts: [{
          name: 'Neven',
          number: '+385913314331'
        }, {
          name: 'Filip',
          number: '+385911636380'
        }, {
          name: 'Ivan',
          number: '+385955077041'
        }, {
          name: 'Duje',
          number: '+385925011515'
        }]
      }, {
        city: 'Zadar',
        fleetContacts: [{
          name: 'Ivan',
          number: '+385955077041'
        }, {
          name: 'Dalibor',
          number: '+385953575659'
        }, {
          name: 'Duje',
          number: '+385925011515'
        }, {
          name: 'Goran',
          number: '+385955009550'
        }]
      }, {
        city: 'Dubrovnik',
        fleetContacts: [{
          name: 'Neven',
          number: '+385913314331'
        }, {
          name: 'Filip',
          number: '+385911636380'
        }, {
          name: 'Hrvoje',
          number: '+385922376373'
        }, {
          name: 'Branko',
          number: '+385916120986'
        }, {
          name: 'Joso',
          number: '+385914848202'
        }, {
          name: 'Duje',
          number: '+385925011515'
        }, {
          name: 'Goran',
          number: '+385955009550'
        }]
      }, {
        city: 'Rijeka',
        fleetContacts: [{
          name: 'Miroslav',
          number: '+385923000983'
        }, {
          name: 'Neven',
          number: '+385913314331'
        }, {
          name: 'Predrag',
          number: '+385917393765'
        }, {
          name: 'Duje',
          number: '+385925011515'
        }, {
          name: 'Goran',
          number: '+385955009550'
        }]
      }, {
        city: 'Osijek',
        fleetContacts: [{
          name: 'Ivana',
          number: '+385981992020'
        }, {
          name: 'Ivan',
          number: '+385955077041'
        }, {
          name: 'Stjepan',
          number: '+385957315650'
        }, {
          name: 'Duje',
          number: '+385925011515'
        }, {
          name: 'Goran',
          number: '+385955009550'
        }]
      }, {
        city: 'Pula',
        fleetContacts: [{
          name: 'Nenad',
          number: '+385996224098'
        }, {
          name: 'Toni',
          number: '+385976252024'
        }, {
          name: 'Duje',
          number: '+385925011515'
        }, {
          name: 'Goran',
          number: '+385955009550'
        }]
      }],
      labels: {
        bolt: 'Bọlt',
        driver: 'Driver',
        subtitle: 'To drive with Bolt in Croatia, you can choose one of the options',
        option1: 'Sign up as a driver',
        option1Sub: 'With, or without your own vehicle',
        option2: 'Sign up as a driver',
        option2Sub: 'Register your own taxi licensed company',
        steps: [{
          containFleetInfo: true,
          header: 'Step 1. Contact a Bolt Partner',
          subtitle: 'Check the list of active taxi licensed companies in your preferred city and feel free to contact any of them to start driving on the Bolt Platform.'
        }, {
          header: 'Step 2. Provide documents',
          subtitle: 'After you find your preferred Bolt Partner you will need the following documents and conditions. Don’t worry, your preferred partner will be happy to help you with acquiring needed documents:',
          paras: [Ember.String.htmlSafe("\n            <ul>\n              <li>Minimum of 21 years of age;</li>\n              <li>Valid Personal ID card;</li>\n              <li>Valid Driver License (B category);</li>\n              <li>Criminal Registry check (download from eGradani);</li>\n              <li>Taxi exam (your preferred partner will share more details about it).</li>\n            </ul>\n          ")]
        }, {
          header: 'Step 3. Wait to be registered in the system',
          subtitle: 'After you have acquired all documents, your partner will create an official driver registration which will be accepted by Bolt within 48 hours.',
          paras: ['When your driver profile is accepted, you will receive an SMS with the instructions how to set up a password and get familiar with the Bolt Driver App.', Ember.String.htmlSafe('<a href="https://www.google.com">Happy driving!</a>')]
        }],
        goToFleet: 'Go to Fleet Owner portal',
        goToFleetSub: Ember.String.htmlSafe('<a>How to become a Bolt Fleet partner</a>')
      }
    },
    hr: {
      cities: [{
        city: 'Zagreb',
        fleetContacts: [{
          name: 'Voditelj flote Antonio',
          number: '+385976353912'
        }, {
          name: 'Voditelj flote Toni',
          number: '+385976252024'
        }, {
          name: 'Voditelj flote Miroslav',
          number: '+385923000983'
        }, {
          name: 'Voditelj flote Stjepan',
          number: '+385957315650'
        }, {
          name: 'Voditelj flote Dalibor',
          number: '+385953575659'
        }, {
          name: 'Voditelj flote Ivica',
          number: '+38598751875'
        }]
      }, {
        city: 'Split',
        fleetContacts: [{
          name: 'Voditelj flote Neven',
          number: '+385913314331'
        }, {
          name: 'Voditelj flote Filip',
          number: '+385911636380'
        }, {
          name: 'Voditelj flote Ivan',
          number: '+385955077041'
        }, {
          name: 'Voditelj flote Duje',
          number: '+385925011515'
        }]
      }, {
        city: 'Zadar',
        fleetContacts: [{
          name: 'Voditelj flote Ivan',
          number: '+385955077041'
        }, {
          name: 'Voditelj flote Dalibor',
          number: '+385953575659'
        }, {
          name: 'Voditelj flote Duje',
          number: '+385925011515'
        }, {
          name: 'Voditelj flote Goran',
          number: '+385955009550'
        }]
      }, {
        city: 'Dubrovnik',
        fleetContacts: [{
          name: 'Voditelj flote Neven',
          number: '+385913314331'
        }, {
          name: 'Voditelj flote Filip',
          number: '+385911636380'
        }, {
          name: 'Voditelj flote Hrvoje',
          number: '+385922376373'
        }, {
          name: 'Voditelj flote Branko',
          number: '+385916120986'
        }, {
          name: 'Voditelj flote Joso',
          number: '+385914848202'
        }, {
          name: 'Voditelj flote Duje',
          number: '+385925011515'
        }, {
          name: 'Voditelj flote Goran',
          number: '+385955009550'
        }]
      }, {
        city: 'Rijeka',
        fleetContacts: [{
          name: 'Voditelj flote Miroslav',
          number: '+385923000983'
        }, {
          name: 'Voditelj flote Neven',
          number: '+385913314331'
        }, {
          name: 'Voditelj flote Predrag',
          number: '+385917393765'
        }, {
          name: 'Voditelj flote Duje',
          number: '+385925011515'
        }, {
          name: 'Voditelj flote Goran',
          number: '+385955009550'
        }]
      }, {
        city: 'Osijek',
        fleetContacts: [{
          name: 'Voditelj flote Ivana',
          number: '+385981992020'
        }, {
          name: 'Voditelj flote Ivan',
          number: '+385955077041'
        }, {
          name: 'Voditelj flote Stjepan',
          number: '+385957315650'
        }, {
          name: 'Voditelj flote Duje',
          number: '+385925011515'
        }, {
          name: 'Voditelj flote Goran',
          number: '+385955009550'
        }]
      }, {
        city: 'Pula',
        fleetContacts: [{
          name: 'Voditelj flote Nenad',
          number: '+385996224098'
        }, {
          name: 'Voditelj flote Toni',
          number: '+385976252024'
        }, {
          name: 'Voditelj flote Duje',
          number: '+385925011515'
        }, {
          name: 'Voditelj flote Goran',
          number: '+385955009550'
        }]
      }],
      labels: {
        bolt: 'Bọlt',
        driver: 'Driver',
        subtitle: 'Kako bi počeli voziti putem Bolt platforme u Hrvatskoj, možete odabrati jednu od ponuđenih opcija',
        option1: 'Prijavite se kao vozač',
        option1Sub: 'Sa ili bez vlastitog vozila',
        option2: 'Prijavite se kao Bolt Partner vlasnik flote',
        option2Sub: 'Registrirajte vlastitu licenciranu taxi tvrtku ili obrt',
        steps: [{
          containFleetInfo: true,
          header: '1. Korak - Kontaktirajte Bolt Partnera',
          subtitle: 'Ako želite početi voziti kao vozač na Bolt platformi, preporučujemo Vam pregledajte i kontaktirajte dolje navedene kontakte već postojećih licenciranih taxi tvrtki kako bi mogli početi voziti i ostvarivati odličan prihod! '
        }, {
          header: '2. Korak - Prikupite potrebne dokumente',
          subtitle: 'Nakon što ste pronašli preferiranog Bolt Partnera, sljedeći korak je prikupljanje potrebnih dokumenata (većinu od njih već posjedujete). Bez brige, Vaš Bolt Partner rado će Vam pomoći s prikupljanjem preostalih dokumenata:',
          paras: [Ember.String.htmlSafe("\n            <ul>\n              <li>Minimalno 21 godina starosti</li>\n               <li>Va\u017Ee\u0107a osobna iskaznica</li>\n               <li>Va\u017Ee\u0107a voza\u010Dka dozvola (B kategorija)</li>\n               <li>Izvod iz kaznene evidencije (mogu\u0107e preuzeti putem portala e-Gra\u0111ani)</li>\n               <li>Po\u010Detna kvalifikacija za autotaksi voza\u010Da (vi\u0161e informacija o navedenom dokumentu, pru\u017Eit \u0107e Vam Bolt Partner)</li>\n            </ul>\n          ")]
        }, {
          header: '3. Korak - Pričekajte odobrenje dokumenata i aktivaciju profila',
          subtitle: 'Nakon što ste pronašli Bolt Partnera i prikupili sve potrebne dokumente, Bolt Partner će Vam kreirati registraciju za vozačev profil koji će biti pregledan od strane Bolta unutar 48 sati.',
          paras: ['Kada se Vaš vozačev profil pregleda i odobri, primit ćete SMS sa linkom za postavljanje lozinke te upute kako koristiti Bolt Driver aplikaciju.', 'Sretna vožnja!']
        }],
        goToFleet: 'Idi na portal Vlasnika Flote',
        goToFleetSub: Ember.String.htmlSafe('<a href="https://support.taxify.eu/hc/hr/articles/360012310554-Uvjeti-za-postati-Bolt-partner-vlasnik-flote">Kako postati Bolt Partner</a>')
      }
    },
    ro: {
      cities: [{
        city_name: 'Bucharest',
        city: 'București',
        fleetContacts: [{
          name: 'Viorel',
          number: '+40769506030',
          text: 'Oferă mașini'
        }, {
          name: 'Alex',
          number: '+40763795939',
          text: 'Oferă mașini'
        }, {
          name: 'Marcu Constantin',
          number: '+40762333200',
          text: 'Oferă mașini'
        }, {
          name: 'Denisa',
          number: '+40771690251',
          text: 'Oferă mașini'
        }, {
          name: 'Serghei',
          number: '+40757439284'
        }, {
          name: 'Decebal',
          number: '+40723003456',
          text: 'Oferă mașini'
        }, {
          name: 'Marian',
          number: '+40735889425',
          text: 'Oferă mașini'
        }]
      }, {
        city_name: 'Cluj-Napoca',
        city: 'Cluj',
        fleetContacts: [{
          name: 'Bogdan',
          number: '+40741244908'
        }, {
          name: 'Vasile',
          number: '+40744915105',
          text: 'Oferă mașini'
        }, {
          name: 'Daniel',
          number: '+40745282746'
        }, {
          name: 'George',
          number: '+40720154653'
        }, {
          name: 'Ciprian',
          number: '+40751037161'
        }, {
          name: 'Levente',
          number: '+40755870872'
        }, {
          name: 'Lucian',
          number: '+40770553620',
          text: 'Oferă mașini'
        }]
      }, {
        city_name: 'Iasi',
        city: 'Iași',
        fleetContacts: [{
          name: 'Razvan',
          number: '+40756561545',
          text: 'Oferă mașini'
        }, {
          name: 'Mariana Hrib',
          number: '+40722415695',
          text: 'Oferă mașini'
        }, {
          name: 'Ciprian',
          number: '+40771437526',
          text: 'Oferă mașini'
        }, {
          name: 'Ionel',
          number: '+40746631553',
          text: 'Oferă mașini'
        }]
      }, {
        city_name: 'Brasov',
        city: 'Brașov',
        fleetContacts: [{
          name: 'George',
          number: '+40720154653'
        }, {
          name: 'Laurentiu',
          number: '+40726061490',
          text: 'Oferă mașini'
        }, {
          name: 'Stelios',
          number: '+40721100291',
          text: 'Oferă mașini'
        }, {
          name: 'Carmen',
          number: '+40731721406',
          text: 'Oferă mașini'
        }]
      }],
      labels: {
        bolt: 'Bọlt',
        driver: 'Driver',
        header: 'Pentru a conduce cu Bolt în România, alege una din următoarele opțiuni',
        subtitle: Ember.String.htmlSafe("\n        <span>\xCEnainte de a deveni \u0219ofer partener pe propriul PFA, \xCEntreprindere Individual\u0103 sau SRL, este necesar s\u0103 te asiguri c\u0103 ai efectuat o \xEEnregistrare de flot\u0103 partener\u0103 Bolt.</span>\n        <a href=\"https://fleets.bolt.eu/signup?utm_source=driver-portal&amp;utm_medium=step-2\">\xCEnregistreaz\u0103-te ca proprietar de flot\u0103</a>\n      "),
        option1: 'Nu sunt înscris încă în nicio flotă parteneră',
        option1Sub: 'Nu dețin o copie conformă',
        option2: 'Sunt înscris deja într-o flotă parteneră',
        option2Sub: 'Dețin o copie conformă',
        steps: [{
          containFleetInfo: true,
          header: 'Pasul 1. Contactează o flotă parteneră Bolt',
          subtitle: 'Verifică mai jos lista flotelor partenere Bolt, active în orașul tău. După ce alegi flota parteneră dorită, nu ezita să-i contactezi reprezentantul pentru mai multe detalii. Te rugăm să ții cont de următorul program de lucru al flotelor: Luni - Vineri, orele 09:00 - 18:00 (cu excepția sărbătorilor legale).'
        }, {
          header: 'Pasul 2. Obține setul complet de documente',
          subtitle: 'După ce îți alegi o flotă parteneră Bolt, vei avea nevoie de câteva documente pentru a-ți activa contul. Nu îți face griji dacă o parte din ele nu îți sunt cunoscute, flota parteneră te va ajuta să le obții. Lista de documente necesare este:',
          paras: [Ember.String.htmlSafe("\n            <ul>\n              <li>Carte de identitate (v\xE2rst\u0103 minim\u0103 21 de ani);</li>\n              <li>Permis de conducere valabil (categoria B) mai vechi de 2 ani;</li>\n              <li>Cazier judiciar;<p>\xCEl ob\u021Bii de la orice sec\u021Bie de poli\u021Bie. Este necesar ca acest document s\u0103 nu fie mai vechi de 6 luni.</p></li>\n              <li>Atestat pentru transport persoane \xEEn regim de \xEEnchiriere;<p>Acesta este unul dintre cele mai importante documente. Atestatul \xEEl po\u021Bi ob\u021Bine de la Autoritatea Rutier\u0103 Rom\xE2n\u0103. Nu ezita s\u0103 vorbe\u0219ti cu managerul flotei tale, el te poate ajuta \u0219i \xEEndruma la fiecare pas.</p></li>\n              <li>Copie conform\u0103;<p>Acest document este eliberat de ARR ca urmare a eliber\u0103rii autoriza\u021Biei pentru transport alternativ. Flota cu care colaborezi te poate ajuta cu acest document.</p></li>\n              <li>Ecuson Bolt;<p>Acest document este eliberat de ARR ca urmare a eliber\u0103rii autoriza\u021Biei pentru transport alternativ. Flota cu care colaborezi te poate ajuta cu acest document.</p></li>\n              <li>Certificat de \xEEnmatriculare vehicul (Talonul ma\u0219inii);</li>\n              <li>Asigurare RCA;</li>\n              <li>Asigurare pentru c\u0103l\u0103tori \u0219i bagaje;</li>\n              <li>Poz\u0103 de profil;</li>\n            </ul>\n          ")]
        }, {
          header: 'Pasul 3. Așteaptă sa fii înregistrat în sistem',
          subtitle: 'După ce ai obținut toate documentele, managerul flotei tale va crea o înregistrare de șofer partener, care va fi acceptată de Bolt în termen de 48 de ore.',
          paras: ['Când înregistrarea ta de șofer partener este acceptată, vei primi un SMS cu instrucțiunile despre cum să configurezi o parolă și să te familiarizezi cu aplicația Bolt Driver.', 'Spor la curse!']
        }],
        goToFleet: 'Continuă Procesul de Înregistrare',
        goToFleetSub: 'Cum te înregistrezi ca șofer partener Bolt'
      },
      shuffleFleets: true
    }
  };
  _exports.default = _default;
});