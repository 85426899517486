define("driver-portal/utils/find-locale-with-fallback", ["exports", "driver-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = findLocaleWithFallback;

  function findLocaleWithFallback(locale) {
    if (!locale) {
      return null;
    }

    var languages = _environment.default.translations.languages;
    var normalizedLocale = locale.toLowerCase(); // Search for a full match

    for (var i = 0, j = languages.length; i < j; i++) {
      var lang = languages[i];

      if (lang.key === normalizedLocale) {
        return lang.key;
      }
    } // Search for a fallback match


    for (var _i = 0, _j = languages.length; _i < _j; _i++) {
      var _lang = languages[_i];

      if (_lang.fallback && _lang.fallback === normalizedLocale) {
        return _lang.key;
      }
    }

    return null;
  }
});