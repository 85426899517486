define("driver-portal/templates/signup/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+TreJlQp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6 col-sm-offset-3\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n          \"],[10,\"h3\"],[14,0,\"text-center\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[35,1,[\"message\"]],\"DRIVER_REGISTRATION_LOG_NOT_IN_PROGRESS\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],[\"error_registration_finished\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[30,[36,0],[\"error\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"p\"],[14,0,\"text-center lead\"],[12],[1,[30,[36,0],[[35,1,[\"message\"]]],null]],[13],[2,\"\\n          \"],[10,\"ul\"],[12],[2,\"\\n            \"],[10,\"li\"],[12],[2,\"\\n              \"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"errorpage_taxify_driver_registration\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"li\"],[12],[2,\"\\n              \"],[6,[37,5],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"errorpage_driver_portal_login\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row m-t-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n      \"],[1,[34,6]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"tb\",\"model\",\"eq\",\"if\",\"new-landing-page-link\",\"link-to\",\"language-selector\"]}",
    "moduleName": "driver-portal/templates/signup/error.hbs"
  });

  _exports.default = _default;
});