define("driver-portal/templates/reset-password/token-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2W3d/XOF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"PASSWORD_RESET_REQUEST_IS_EXPIRED\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"alert alert-success text-center\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"password_resend_email_success\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"text-center\"],[12],[1,[30,[36,0],[\"password_resend_email_success_close_window\"],null]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center m-t-lg m-b-lg\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"class\"],[\"btn-xs\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"tb\",\"language-selector\"]}",
    "moduleName": "driver-portal/templates/reset-password/token-expired.hbs"
  });

  _exports.default = _default;
});