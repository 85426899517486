define("driver-portal/services/user-preferences", ["exports", "@bolteu/ember-commons/services/local-storage"], function (_exports, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localStorage.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('namespace', 'taxifyDriverPortal');
    }
  });

  _exports.default = _default;
});