define("driver-portal/utils/uuid-helper", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateUuid;

  // hack to use ember-uuid library in TS code
  function generateUuid() {
    return (0, _emberUuid.v4)();
  }
});