define("driver-portal/templates/circle-k/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mIp4Bv9B",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"circle-k-message\"],[14,0,\"col-sm-12 text-center\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"text\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"strong\"],[12],[1,[32,0,[\"text\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"strong\"],[12],[1,[32,0,[\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"lead text-muted\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"circle_k.contact_customer_support\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,1,\"circle-k-form\"],[14,0,\"col-sm-4 col-sm-offset-4 text-center\"],[12],[2,\"\\n  \"],[10,\"form\"],[14,\"method\",\"POST\"],[14,0,\"margin-top-33\"],[12],[2,\"\\n    \"],[10,\"button\"],[15,\"onClick\",[30,[36,2],[[32,0],\"close\"],null]],[14,0,\"btn btn-secondary btn-lg btn-block\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"circle_k.registration_completed_close\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"tb\",\"action\"]}",
    "moduleName": "driver-portal/templates/circle-k/error.hbs"
  });

  _exports.default = _default;
});