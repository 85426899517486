define("driver-portal/templates/components/expandable-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ywQCH5xD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,3],[[35,2],\"expandable-content-expanded\",\"expandable-content-collapsed\"],null]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"btn btn-link\"],[15,\"onclick\",[30,[36,0],[[32,0],\"showLess\"],null]],[14,4,\"button\"],[12],[2,\"\\n     \"],[1,[30,[36,1],[\"extendable_content.show_less\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"btn btn-link\"],[15,\"onclick\",[30,[36,0],[[32,0],\"showMore\"],null]],[14,4,\"button\"],[12],[2,\"\\n     \"],[1,[30,[36,1],[\"extendable_content.show_more\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"tb\",\"isExpanded\",\"if\"]}",
    "moduleName": "driver-portal/templates/components/expandable-content.hbs"
  });

  _exports.default = _default;
});