define("driver-portal/templates/components/vehicle-application/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nD+NAnKC",
    "block": "{\"symbols\":[\"type\",\"classSuffix\",\"@comment\",\"@isDeclined\",\"@isRecent\"],\"statements\":[[6,[37,3],[[30,[36,5],[[32,4],[32,5]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,4],\"declined\",\"created\"],null],[30,[36,3],[[32,4],\"declined\",\"warning\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[30,[36,0],[\"my-lg-3 my-sm-2 p-lg-3 p-sm-3 alert alert-\",[32,2]],null]],[14,\"role\",\"alert\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"d-flex flex-row flex-wrap\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon icon-warning\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"d-flex flex-column flex-wrap\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"text-bold\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],[\"auth.add-vehicle.application-\",[32,1]],null]],null]],[2,\"\\n          \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,4],[32,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"tb\",\"and\",\"if\",\"let\",\"or\"]}",
    "moduleName": "driver-portal/templates/components/vehicle-application/alert.hbs"
  });

  _exports.default = _default;
});