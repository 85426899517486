define("driver-portal/computed/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchByField = searchByField;

  function searchByField(dataKey, searchTermKey, fieldKey) {
    return Ember.computed(dataKey, searchTermKey, function () {
      var searchTerm = this.get(searchTermKey);
      var dataSet = this.get(dataKey);

      if (!searchTerm) {
        return dataSet;
      }

      return dataSet.filter(function (item) {
        // get() for POJOs
        return Ember.get(item, fieldKey).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
      });
    }).readOnly();
  }
});