define("driver-portal/templates/components/app-store-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Q+bAxHX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[\"bolt_app.install_bolt_app\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"a\"],[15,6,[31,[[32,0,[\"driverAppLink\"]]]]],[12],[2,\"\\n              \"],[10,\"img\"],[15,\"src\",[30,[36,0],[\"/assets/img/driver-app-store-badges/app-store.svg\"],null]],[15,\"alt\",[30,[36,1],[\"bolt_app.app_store_alt\"],null]],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"a\"],[15,6,[31,[[32,0,[\"driverAppLink\"]]]]],[12],[2,\"\\n              \"],[10,\"img\"],[15,\"src\",[30,[36,0],[\"/assets/img/driver-app-store-badges/google-play.svg\"],null]],[15,\"alt\",[30,[36,1],[\"bolt_app.google_play_alt\"],null]],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"a\"],[15,6,[31,[[32,0,[\"driverAppLink\"]]]]],[12],[2,\"\\n              \"],[10,\"img\"],[15,\"src\",[30,[36,0],[\"/assets/img/driver-app-store-badges/huawei-app-gallery.svg\"],null]],[15,\"alt\",[30,[36,1],[\"bolt_app.huawei_app_gallery_alt\"],null]],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"asset-map\",\"t\",\"tb\",\"displayHint\",\"if\",\"showAppStoreBadge\",\"showGooglePlayBadge\",\"showHuaweiAppGalleryBadge\",\"showStoresButtons\"]}",
    "moduleName": "driver-portal/templates/components/app-store-links.hbs"
  });

  _exports.default = _default;
});