define("driver-portal/models/document", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    created: attr('number'),
    expires: attr('number'),
    is_expired: attr('boolean'),
    expires_in_days: attr('number'),
    document_type_name: attr('string'),
    document_type_description: attr('string'),
    original_file_name: attr('string'),
    can_delete: attr('boolean', {
      defaultValue: false
    }),
    can_view: attr('boolean', {
      defaultValue: false
    }),
    status: attr('string'),
    decline_reason: attr('string'),
    document_id: Ember.computed.alias('id'),
    document_name: Ember.computed.alias('original_file_name'),
    field_name: Ember.computed.alias('document_type_name'),
    isUploaded: Ember.computed.notEmpty('id'),
    canDelete: Ember.computed.alias('can_delete'),
    canView: Ember.computed.alias('can_view'),
    insurance_provider_id: attr('number'),
    expiresInLocalTime: Ember.computed('expires', function () {
      var expires = Number(this.expires);

      if (expires > 0) {
        return new Date(expires * 1000).toLocaleDateString();
      }

      return null;
    }),
    createdInLocalTime: Ember.computed('created', function () {
      var expires = Number(this.created);

      if (expires > 0) {
        return new Date(expires * 1000).toLocaleDateString();
      }

      return null;
    })
  });

  _exports.default = _default;
});