define("driver-portal/models/car-document-set", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    document: _emberData.default.belongsTo('document'),
    uploaded_document: _emberData.default.belongsTo('document'),
    document_type: _emberData.default.belongsTo('document-type'),
    field_title: Ember.computed.readOnly('document_type.title'),
    field_description: Ember.computed.readOnly('document_type.description'),
    is_required: Ember.computed.readOnly('document_type.is_required'),
    can_modify_documents: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});