define("driver-portal/models/car-application-document-set", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    document: _emberData.default.belongsTo('document'),
    document_type: _emberData.default.belongsTo('document-type'),
    // TODO maybe remove this backward compatibility layer for {{upload-document}} component
    field_title: Ember.computed.readOnly('document_type.title'),
    field_description: Ember.computed.readOnly('document_type.description'),
    is_required: Ember.computed.readOnly('document_type.is_required')
  });

  _exports.default = _default;
});