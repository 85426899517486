define("driver-portal/components/vehicle-status-css-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STATUS_CSS_CLASS = void 0;
  var STATUS_CSS_CLASS = {
    submitted: 'label-info',
    onboarding: 'label-info',
    declined: 'label-danger',
    declined_silently: 'label-danger',
    in_progress: 'label-default',
    needs_resubmission: 'label-danger',
    resubmitted: 'label-info'
  };
  _exports.STATUS_CSS_CLASS = STATUS_CSS_CLASS;
});