define("driver-portal/templates/reset-password-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+0xbnqYQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6 col-sm-offset-3\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n          \"],[10,\"h3\"],[14,0,\"text-center\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[2,\"\\n              \"],[1,[30,[36,0],[\"error\"],null]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"p\"],[14,0,\"text-center lead\"],[12],[1,[30,[36,0],[[35,1,[\"message\"]]],null]],[13],[2,\"\\n          \"],[10,\"ul\"],[12],[2,\"\\n            \"],[10,\"li\"],[12],[2,\"\\n              \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"errorpage_taxify_driver_registration\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"li\"],[12],[2,\"\\n              \"],[6,[37,3],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"errorpage_driver_portal_login\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row m-t-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n      \"],[1,[34,4]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"tb\",\"model\",\"new-landing-page-link\",\"link-to\",\"language-selector\"]}",
    "moduleName": "driver-portal/templates/reset-password-error.hbs"
  });

  _exports.default = _default;
});