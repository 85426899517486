define("driver-portal/templates/login/magic-login-partner-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yU1FD3w2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"m-t-md m-b-lg\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-center lead\"],[12],[1,[30,[36,0],[\"magic-login.tokenExpired\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[6,[37,1],null,[[\"activeClass\",\"route\"],[\"\",\"login\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"errorpage_driver_portal_login\"],null]]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"errorpage_taxify_driver_registration\"],null]]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tb\",\"link-to\",\"new-landing-page-link\"]}",
    "moduleName": "driver-portal/templates/login/magic-login-partner-error.hbs"
  });

  _exports.default = _default;
});