define("driver-portal/templates/components/submit-confirmation-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ITNonkuK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"modal-backdrop\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"submit-confirmation-dialog modal-dialog\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal modal-open\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n          \"],[10,\"h2\"],[14,0,\"modal-title\"],[12],[1,[30,[36,1],[[35,0]],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n          \"],[10,\"p\"],[12],[1,[30,[36,1],[[35,2]],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n            \"],[10,\"button\"],[15,\"onClick\",[30,[36,3],[[32,0],\"confirmAction\"],null]],[14,0,\"btn confirm\"],[14,4,\"button\"],[12],[2,\"\\n              \"],[1,[30,[36,1],[[35,4]],null]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[10,\"button\"],[15,\"onClick\",[30,[36,3],[[32,0],\"cancelAction\"],null]],[14,0,\"btn cancel\"],[14,4,\"button\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[35,5]],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"title\",\"tb\",\"text\",\"action\",\"confirm\",\"cancel\"]}",
    "moduleName": "driver-portal/templates/components/submit-confirmation-dialog.hbs"
  });

  _exports.default = _default;
});