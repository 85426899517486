define("driver-portal/utils/event-tracking/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EVENTS = _exports.PAGES = _exports.DRIVER_REGISTRATION = void 0;
  var DRIVER_REGISTRATION = 'DRIVER_REGISTRATION';
  _exports.DRIVER_REGISTRATION = DRIVER_REGISTRATION;
  var PAGES;
  _exports.PAGES = PAGES;

  (function (PAGES) {
    PAGES["DP_LANDING"] = "DP_LANDING";
    PAGES["DP_STEP_2"] = "DP_STEP_2";
    PAGES["DP_VERIFIED"] = "DP_VERIFIED";
  })(PAGES || (_exports.PAGES = PAGES = {}));

  var EVENTS;
  _exports.EVENTS = EVENTS;

  (function (EVENTS) {
    EVENTS["FORM_SUBMITTED"] = "FORM_SUBMITTED";
    EVENTS["PAGE_RENDERED"] = "PAGE_RENDERED";
    EVENTS["SHARE_PARTNER_TOKEN_DEEPLINK"] = "SHARE_PARTNER_TOKEN_DEEPLINK";
    EVENTS["SHARE_PARTNER_TOKEN_DRP"] = "SHARE_PARTNER_TOKEN_DRP";
    EVENTS["IN_APP_SIGNUP_BEFORE_TARGETING"] = "IN_APP_SIGNUP_BEFORE_TARGETING";
    EVENTS["IN_APP_SIGNUP_TREATMENT"] = "IN_APP_SIGNUP_TREATMENT";
    EVENTS["IN_APP_SIGNUP_TREATMENT_WITH_NEW_VERIFICATION"] = "IN_APP_SIGNUP_TREATMENT_WITH_NEW_VERIFICATION";
    EVENTS["IN_APP_SIGNUP_CLASSIC"] = "IN_APP_SIGNUP_CLASSIC";
  })(EVENTS || (_exports.EVENTS = EVENTS = {}));
});