define("driver-portal/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HN0iyWac",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"],[13]],\"hasEval\":false,\"upvars\":[\"splash-screen\"]}",
    "moduleName": "driver-portal/templates/loading.hbs"
  });

  _exports.default = _default;
});