define("driver-portal/helpers/from-unix", ["exports", "@bolteu/ember-commons/helpers/from-unix"], function (_exports, _fromUnix) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fromUnix.default;
    }
  });
  Object.defineProperty(_exports, "fromUnix", {
    enumerable: true,
    get: function get() {
      return _fromUnix.fromUnix;
    }
  });
});