define("driver-portal/templates/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8c/9p6V3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"progress-bar\"],[14,\"role\",\"progressbar\"],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[15,5,[34,0]],[12],[13]],\"hasEval\":false,\"upvars\":[\"progressWidth\"]}",
    "moduleName": "driver-portal/templates/components/progress-bar.hbs"
  });

  _exports.default = _default;
});