define("driver-portal/templates/components/vehicle-application/alert-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iQqAqrDJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[\"my-lg-3 my-sm-2 p-lg-3 p-sm-3 alert alert-custom alert-info\"],null]],[14,\"role\",\"alert\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon icon-info-with-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex flex-column flex-wrap\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"alert-title text-bold\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],[\"auth.add-vehicle.application-in-progress.title\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[\"auth.add-vehicle.application-in-progress.description\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"tb\"]}",
    "moduleName": "driver-portal/templates/components/vehicle-application/alert-info.hbs"
  });

  _exports.default = _default;
});