define("driver-portal/templates/components/fleet-info/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UwRTftuQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"style\"],[12],[2,\"\\n  .fi-radio-btn-selected {\\n    border: var(--fleet-info-accent-color) solid 4px;\\n  }\\n\\n  .fi-radio-btn {\\n    margin-top: 4px;\\n    border-radius: 7px;\\n    height: 14px;\\n    width: 14px;\\n  }\\n\\n  .fi-radio-btn-unselected {\\n    border: darkgray solid 2px;\\n  }\\n\\n  .fi-radio-container {\\n    display: flex;\\n    justify-content: left;\\n    gap: 10px;\\n  }\\n\\n  .fi-radio-label-container {\\n    align-self: flex-start;\\n    text-align: left;\\n    margin-bottom: 16px\\n  }\\n\\n  .fi-radio-btn-container {\\n    height: min-content;\\n  }\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"fi-radio-container\"],[12],[2,\"\\n\\n    \"],[10,\"button\"],[14,0,\"fi-bg-white btn-xs fi-radio-btn-container btn-borderless\"],[15,\"onclick\",[30,[36,0],[[32,0],\"onSelection\"],null]],[14,4,\"button\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"fi-radio-btn fi-radio-btn-selected\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"fi-radio-btn fi-radio-btn-unselected\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"fi-radio-label-container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"fi-font-regular\"],[12],[1,[34,3]],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"fi-font-small\"],[12],[1,[34,4]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"selected\",\"if\",\"label\",\"sublabel\"]}",
    "moduleName": "driver-portal/templates/components/fleet-info/radio-button.hbs"
  });

  _exports.default = _default;
});