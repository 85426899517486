define("driver-portal/templates/components/vehicle-application/call-to-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gPvY+sTU",
    "block": "{\"symbols\":[\"@isDeclined\"],\"statements\":[[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center mt-lg-3 mt-sm-2\"],[12],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"mb-lg-2 mb-sm-2\"],[12],[1,[30,[36,0],[\"auth.vehicle-application.submit-new-application\"],null]],[13],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-primary border-radius-100 text-uppercase px-lg-3 py-lg-2 px-sm-3 py-sm-2\",\"auth.add-vehicle\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"auth.vehicle-application.new-application\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tb\",\"link-to\",\"if\"]}",
    "moduleName": "driver-portal/templates/components/vehicle-application/call-to-action.hbs"
  });

  _exports.default = _default;
});