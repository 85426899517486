define("driver-portal/components/form-group", ["exports", "@bolteu/ember-commons/components/form-group"], function (_exports, _formGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formGroup.default;
    }
  });
});