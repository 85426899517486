define("driver-portal/utils/build-absolute-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildAbsoluteUrl;

  function buildAbsoluteUrl(path) {
    var resultPath = path;

    if (path.indexOf('/') !== 0) {
      resultPath = '/' + path;
    }

    return window.location.origin + resultPath;
  }
});