define("driver-portal/utils/meta-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    configure: function configure(properties) {
      if (properties.description) {
        var _document$querySelect;

        (_document$querySelect = document.querySelector('meta[name=description]')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.setAttribute('content', properties.description);
      }

      if (properties.ogTitle) {
        var _document$querySelect2;

        (_document$querySelect2 = document.querySelector('meta[property="og:title"]')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.setAttribute('content', properties.ogTitle);
      }

      if (properties.ogImage) {
        var _document$querySelect3;

        (_document$querySelect3 = document.querySelector('meta[property="og:image"]')) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.setAttribute('content', properties.ogImage);
      }
    }
  };
  _exports.default = _default;
});