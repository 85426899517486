define("driver-portal/utils/in-memory-storage", ["exports", "@bolteu/ember-commons/utils/in-memory-storage"], function (_exports, _inMemoryStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _inMemoryStorage.default;
    }
  });
});