define("driver-portal/initializers/reset-scroll-position", ["exports", "@bolteu/ember-commons/mixins/reset-scroll-position"], function (_exports, _resetScrollPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    Ember.Route.reopen(_resetScrollPosition.default);
  }

  var _default = {
    name: 'reset-scroll-position',
    initialize: initialize
  };
  _exports.default = _default;
});