define("driver-portal/templates/components/menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tzGcaIUS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"route\"],[[35,3]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[\"icon \",[34,0]]]],[12],[13],[2,\" \"],[1,[34,1]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isHighlighted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"highlight-dot\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iconClass\",\"caption\",\"if\",\"link\",\"link-to\"]}",
    "moduleName": "driver-portal/templates/components/menu-item.hbs"
  });

  _exports.default = _default;
});