define("driver-portal/templates/signup/step1/continue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RE4OXYWE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"lead\"],[12],[2,\"\\n  \"],[10,\"strong\"],[12],[1,[30,[36,1],[\"regstep1_signup_title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"welcome-back lead\"],[12],[1,[30,[36,1],[\"regstep1_welcome_back\"],[[\"fullname\"],[[35,2,[\"informativeName\"]]]]]],[13],[2,\"\\n\"],[10,\"p\"],[12],[1,[30,[36,1],[\"regstep1_welcome_back_lead\"],null]],[13],[2,\"\\n\"],[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],[[\"action\"],[[30,[36,0],[[32,0],\"continueRegistration\",[35,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[\"regstep1_continue\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"text-muted\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,6,\"\"],[14,1,\"signup-now-link\"],[15,\"onclick\",[30,[36,0],[[32,0],\"signupNow\"],null]],[12],[1,[30,[36,1],[\"regstep1_welcome_back_not_this_person\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"tb\",\"model\",\"registration\",\"save-button\",\"form-group\"]}",
    "moduleName": "driver-portal/templates/signup/step1/continue.hbs"
  });

  _exports.default = _default;
});