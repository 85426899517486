define("driver-portal/templates/components/signup-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tmggpBSc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"wizard-progress wizard-5-steps clearfix center-block\"],[12],[2,\"\\n  \"],[10,\"li\"],[15,0,[34,0]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-num\"],[12],[2,\"1\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-name hidden-xs\"],[12],[1,[30,[36,1],[\"progress_contacts\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[15,0,[34,2]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-num\"],[12],[2,\"2\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-name hidden-xs\"],[12],[1,[30,[36,1],[\"progress_personal_n_car_info\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[15,0,[34,3]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-num\"],[12],[2,\"3\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-name hidden-xs\"],[12],[1,[30,[36,1],[\"progress_documents_n_fees\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[15,0,[34,4]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-num\"],[12],[2,\"4\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-name hidden-xs\"],[12],[1,[30,[36,1],[\"progress_documents\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[15,0,[34,5]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-num\"],[12],[2,\"5\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"step-name hidden-xs\"],[12],[1,[30,[36,1],[\"progress_payment_details\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"step1Class\",\"tb\",\"step2Class\",\"step3Class\",\"step4Class\",\"step5Class\"]}",
    "moduleName": "driver-portal/templates/components/signup-progress-bar.hbs"
  });

  _exports.default = _default;
});