define("driver-portal/templates/circle-k", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2rxTBWZP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"style\"],[12],[2,\"\\n  html,\\n  body {\\n    height: 100%;\\n    padding: 0;\\n  }\\n\\n  #circle-k {\\n    height: 100%;\\n    padding: 24px;\\n  }\\n\\n  #circle-k .row {\\n    height: 100%;\\n    margin: 0;\\n  }\\n\\n  #circle-k .row div {\\n    padding-left: 0;\\n    padding-right: 0;\\n  }\\n\\n  #circle-k .btn {\\n    border-radius: 40px;\\n  }\\n\\n  #circle-k .btn-primary {\\n    background-color: #5b68f6;\\n    border-color: #5b68f6;\\n  }\\n\\n  #circle-k-logo {\\n    padding-top: 33%;\\n    height: 33%;\\n  }\\n\\n  #circle-k-message {\\n    height: 33%;\\n    padding-top: 10%;\\n    padding-bottom: 10%;\\n  }\\n\\n  #circle-k-message h2{\\n    word-wrap: break-word;\\n  }\\n\\n  #circle-k-form {\\n    height: 33%;\\n  }\\n\\n  #circle-k-form form {\\n    position: absolute;\\n    bottom: 0;\\n    left: 0;\\n    width: 100%;\\n  }\\n\\n  .btn-block+.btn-block {\\n    margin-top: 16px;\\n  }\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"circle-k\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,1,\"circle-k-logo\"],[14,0,\"col-sm-4 col-sm-offset-4 text-center\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,[36,0],[\"/assets/img/circle-k-logo.svg\"],null]],[14,0,\"w-sm cursor-pointer\"],[15,\"alt\",[30,[36,1],[\"circle_k.title\"],null]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"asset-map\",\"tb\",\"-outlet\",\"component\"]}",
    "moduleName": "driver-portal/templates/circle-k.hbs"
  });

  _exports.default = _default;
});