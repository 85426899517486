define("driver-portal/utils/app-version-parsing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAppTypeFromAppVersion = getAppTypeFromAppVersion;
  _exports.getVersionNumberFromAppVersion = getVersionNumberFromAppVersion;

  function getAppTypeFromAppVersion(app_version) {
    return String(app_version.substr(0, 2)).toUpperCase();
  }

  function getVersionNumberFromAppVersion(app_version) {
    return Number(app_version.substr(3));
  }
});