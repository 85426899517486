define("driver-portal/data/registration-help-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ca: {
      step2: {
        vehicleRequirements: {
          title: 'registration.documents.ca.vehicle-requirements',
          href: 'https://support.bolt.eu/hc/en-us/articles/360000112534-Vehicle-Requirements'
        }
      },
      step3: {
        driverRequirements: {
          title: 'registration.documents.ca.driver-requirements',
          href: 'https://support.bolt.eu/hc/en-us/articles/360000500194-Driver-Registration-FAQ'
        }
      },
      step4: {
        documentsRequirements: {
          title: 'registration.documents.ca.document-requirements',
          href: 'https://support.bolt.eu/hc/en-us/articles/360003267894-Driver-Application-Documents'
        }
      }
    }
  };
  _exports.default = _default;
});