define("driver-portal/templates/signup/submitted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+VmTk28e",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-10 col-sm-offset-1\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-10 col-sm-offset-1\"],[12],[2,\"\\n          \"],[10,\"h3\"],[14,0,\"text-center\"],[12],[10,\"strong\"],[12],[1,[30,[36,1],[\"pages.submitted.title\"],[[\"registrationDate\"],[[35,0]]]]],[13],[13],[2,\"\\n          \"],[10,\"p\"],[14,0,\"lead text-center\"],[12],[1,[30,[36,1],[\"pages.submitted.summary\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row m-t-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"date\",\"tb\",\"language-selector\"]}",
    "moduleName": "driver-portal/templates/signup/submitted.hbs"
  });

  _exports.default = _default;
});