define("driver-portal/templates/components/vehicle-application/alert-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iJHf1iTK",
    "block": "{\"symbols\":[\"type\",\"@isDeclined\",\"@comment\"],\"statements\":[[6,[37,5],[[30,[36,4],[[32,2],\"is-declined\",\"resubmission\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[30,[36,2],[\"my-lg-3 my-sm-2 p-lg-3 p-sm-3 alert alert-custom alert-error\"],null]],[14,\"role\",\"alert\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icon icon-warning\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"d-flex flex-column flex-wrap\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"alert-title text-bold\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[[30,[36,2],[\"auth.add-vehicle.application-\",[32,1],\".title\"],null]],null]],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,4],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[12],[1,[30,[36,0],[[30,[36,2],[\"auth.add-vehicle.application-\",[32,1],\".description\"],null]],null]],[13],[2,\"\\n        \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"call-to-action\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-pill btn-secondary\",\"auth.add-vehicle\"]],[[\"default\"],[{\"statements\":[[2,\"              \"],[1,[30,[36,0],[\"auth.add-vehicle.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"tb\",\"link-to\",\"concat\",\"not\",\"if\",\"let\"]}",
    "moduleName": "driver-portal/templates/components/vehicle-application/alert-error.hbs"
  });

  _exports.default = _default;
});