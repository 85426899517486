define("driver-portal/templates/components/copy-to-clipboard-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FZHHUuM4",
    "block": "{\"symbols\":[\"@target\"],\"statements\":[[6,[37,1],[[30,[36,4],null,null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"type\",\"success\",\"class\",\"clipboardTarget\"],[\"button\",[30,[36,2],[[32,0],\"copy\"],null],\"btn btn-link btn-copy-to-clipboard\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[30,[36,1],[[32,0,[\"copied\"]],\"text-muted\"],null]],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"copied\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        ✓ \"],[1,[30,[36,0],[\"copied\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"copy\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tb\",\"if\",\"action\",\"copy-button\",\"is-clipboard-supported\"]}",
    "moduleName": "driver-portal/templates/components/copy-to-clipboard-button.hbs"
  });

  _exports.default = _default;
});